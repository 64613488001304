<template>
   <LayoutBase :isHideHeader="true">
      <div class="block-cover">
         <div class="quiz-content-box">
            <div class="quiz-resul-img"><img :src="result.img" alt="" /></div>
            <div class="quiz-content-result">
               <div class="quiz-content-text">
                  <p class="defalt-quiz-paragraf">Your result</p>
                  <p class="quiz-title-result">
                     {{ result.textContent }}
                  </p>
                  <p class="quiz-paragraf-resul">{{ result.textContentTwo }}</p>
               </div>
               <router-link :to="{ name: 'registration' }">
                  <SWButton class="quiz-btn-resul-one" variant="primari" size="big" fullWidth
                     >Try it for free</SWButton
                  ></router-link
               >

               <SWButton class="quiz-btn-resul-two" variant="secondary" size="big" fullWidth @click="isShare = !isShare"
                  >Share my result</SWButton
               >
               <transition name="fade">
                  <div v-if="isShare" class="share-buttons">
                     <button @click="shareOnFacebook" id="shareBtn" class="share__item">
                        <ResultsShareItem :iconName="'facebookShare.svg'" :name="'facebook'" />
                     </button>
                     <a
                        class="share__item"
                        target="_blank"
                        :href="`https://twitter.com/intent/tweet?text=I completed the Sleep Coach test, and here's my result: ${result.textContent}. To improve your sleep and well-being, use our unique and useful recommendations. 
						Curious about your sleep? Fill out a quick test at sleep-well.coach to learn more about it! link: https://sleep-well.coach`"
                     >
                        <ResultsShareItem :iconName="'twitter.svg'" :name="'twitter'" />
                     </a>
                  </div>
               </transition>
            </div>
         </div>
      </div>
   </LayoutBase>
</template>

<script>
const RESALTQOIZ = [
   {
      id: 1,
      img: require('../assets/img/ImageQresult1.png'),
      textContent: 'You are experiencing some problems with sleep',
      textContentTwo: 'To improve your sleep and well-being, use our unique and useful recommendations',
   },
   {
      id: 2,
      img: require('../assets/img/ImageQresult2.png'),
      textContent: 'You have significant problems with sleep',
      textContentTwo:
         'But, we have prepared unique and most effective recommendations for you, which will help you improve your sleep and well-being',
   },
   {
      id: 3,
      img: require('../assets/img/ImageQresult3.png'),
      textContent: 'You are doing great, keep it up',
      textContentTwo: 'To improve your sleep and well-being, use our unique and useful recommendations',
   },
];

import ResultsShareItem from '../components/ResultsComponents/ResultShareItem.vue';
import LayoutBase from '../components/layouts/LayoutBase.vue';
import { SWButton } from 'sw-ui-kit';
const DEFAULT_RESULT = 3;
export default {
   components: {
      LayoutBase,
      ResultsShareItem,
      SWButton,
   },

   data() {
      return { isShare: false };
   },

   mounted() {
      this.loadFacebookSDK();
      console.log(this.$route.params?.answers);
   },

   computed: {
      result() {
         const answers = this.$route.params?.answers;
         let resultId = DEFAULT_RESULT;
         let countYes = 0;
         let countNo = 0;
         if (answers) {
            Object.keys(answers).forEach(key => {
               if (answers[key] === 'Yes') {
                  countYes += 1;
               }
               if (answers[key] === 'No') {
                  countNo += 1;
               }
            });
            console.log('countYes', countYes);
            console.log('countNo', countNo);
            if (countYes === Object.keys(answers).length) {
               console.log('countYes');
               resultId = 1;
            } else if (countNo === Object.keys(answers).length) {
               console.log('countNo');
               resultId = 2;
            } else {
               console.log('defailt');
               resultId = 3;
            }
         }
         console.log('result quiz!', resultId);
         return RESALTQOIZ.find(el => el.id === resultId);
      },
   },

   methods: {
      loadFacebookSDK() {
         return new Promise(resolve => {
            window.fbAsyncInit = function () {
               // eslint-disable-next-line
               FB.init({
                  appId: '1480226269391198',
                  autoLogAppEvents: true,
                  xfbml: true,
                  version: 'v17.0', // Use the latest version
               });
               resolve(); // Resolve the promise when SDK is initialized
            };

            // Load the Facebook SDK script
            (function (d, s, id) {
               var js,
                  fjs = d.getElementsByTagName(s)[0];
               if (d.getElementById(id)) return;
               js = d.createElement(s);
               js.id = id;
               js.src = 'https://connect.facebook.net/en_US/sdk.js';
               fjs.parentNode.insertBefore(js, fjs);
            })(document, 'script', 'facebook-jssdk');
         });
      },
      shareOnFacebook() {
         // eslint-disable-next-line
         FB.ui({
            method: 'share',
            href: 'https://sleep-well.coach',
            quote: `I got this advice: ${this.result.textContentTwo}`,
         });
      },
   },
};
</script>

<style lang="sass" scoped>
@import '../sass/_variables'

.quiz-content-box
	display: flex
	width: 100%
	height: 100%
	justify-content: center
	align-items: center
	@media screen and (max-width:889px)
		flex-direction: column
		justify-content: center
		align-items: center

.quiz-title-result
	font-size: 32px
	font-weight: 700
	line-height: 41.6px
	text-align: center
	margin-bottom: 32px
.quiz-paragraf-resul
	font-size: 20px
	font-weight: 400
	line-height: 26px
	text-align: center
	margin-bottom: 40px
	@media screen and (max-width:380px)
		margin-bottom: 27px
.block-cover
	width: 100%
	@media screen and (max-width:880px)
		height: 100%
.defalt-quiz-paragraf
	margin-bottom: 32px
	text-align: center
	font-size: 16px
	font-weight: 400
	line-height: 20.8px
.quiz-btn-resul-one
	margin-bottom: 20px
.quiz-resul-img
	max-width: 730px
	width: 100%
	@media screen and (max-width:889px)
		max-width: 550px
	@media screen and (max-width:593px)
		max-width: 450px
.quiz-resul-img img
	@media screen and (max-width:889px)
		margin-bottom: 29.66px
.quiz-content-result
	max-width: 439px
	width: 100%
	margin-right: 60px
	margin-left: 20px
	@media screen and (max-width:889px)
		margin-right: 0
		margin-left: 0

.share-buttons
	margin-top: 20px
	display: flex
	align-items: center
	justify-content: center
	& > *:first-child
		margin-right: 10px
</style>
